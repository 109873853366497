<template>
  <div
      id="app"
      v-if="loaded && $route.name !== 'wait'"
      :class="[
      'app-' +
        type +
        ' page page--' +
        ($route.name ? $route.name : $route.params.page) +
        (user ? ' user--' + user.type : ''),
      {
        'accessible-version':
          user && user.dashboard && user.dashboard.isAccessible,
        'has-podcast': podcast,
      },
    ]"
  >
    <header-component v-if="requireUser"></header-component>
    <navigation
        v-if="
        requireUser &&
        $route.name !== 'buy' &&
        $route.name !== 'foad' &&
        $route.name !== 'positionnement' &&
        $route.name !== 'sondage'
      "
    ></navigation>

    <div class="view-container">
      <transition name="fade">
        <router-view :key="$route.fullPath"></router-view>
      </transition>
    </div>

    <footer-component
        v-if="requireUser && $route.name !== 'foad'"
    ></footer-component>

    <popin v-if="alert" :content="alert" type="alert"></popin>
    <popin
        :content="popin"
        type="popin"
        v-if="popin"
        v-for="popin in popins"
    ></popin>
    <popin v-if="error" :content="error" type="error"></popin>

    <progress-bar
        v-if="backgroundTask.length && $route.name !== 'login'"
        :tasks="backgroundTask"
    ></progress-bar>
    <player :data="podcast" v-if="podcast" :key="podcast.id"></player>

    <tour v-if="user"></tour>
  </div>
  <div id="app" v-else>
    <maintenance v-if="$route.name === 'maintenance'" class="maintenance"
    >Maintenance</maintenance
    >
    <loader
        v-else
        :text="
        $route.name === 'wait' ? 'Merci de patienter' : 'Chargement en cours...'
      "
    ></loader>
    <popin v-if="error" :content="error" type="error"></popin>
  </div>
</template>

<script>
import userRepository from "@/repositories/userRepository";
import configRepository from "@/repositories/configRepository";
import termRepository from "@/repositories/termRepository";
import appendixRepository from "@/repositories/appendixRepository";
import caciRepository from "./repositories/caciRepository";
import companyRepository from "./repositories/companyRepository";

import Vue from "vue";
import cache from "@/services/cache";
import hash from "object-hash";

export default {
  name: "app",
  methods: {
    resize() {
      document.documentElement.style.setProperty(
          "--app-height",
          `${window.innerHeight}px`
      );
    },
    getCookie() {
      let name = "firstmultico=";
      let allCookieArray = document.cookie.split(";");
      for (let i = 0; i < allCookieArray.length; i++) {
        let temp = allCookieArray[i].trim();
        if (temp.indexOf(name) === 0)
          return temp.substring(name.length, temp.length);
      }
      return "";
    },
    redirect(user) {
      if (user) {
        if (this.$route.name === "document") {
          let id = this.$route.params.id;

          this.$router.push({
            name: "documents",
            params: { section: "informations-juridiques" },
          });
          this.$popin("preview", { type: "document", id: id });
        }
        this.isMigrating = this.redirectMultico(user)
        return this.isMigrating;
      }
      return false;
    },
    redirectMultico(user) {
      const multicoDate = user.company.multico_activation_date;

      if (user.changePassword && user.contact) {
        this.$popin("migrate", user.contact, false);
        return true;
      } else {
        if (user.type === "company" && user.canCreateAccount) {

          const urlParams = new URLSearchParams(window.location.search);

          let daysFromMultico = this.daysFromMultico(multicoDate);

          if (daysFromMultico > 60 && !urlParams.has("migrate")) {
            this.$router.push({ name: "migrate" });
            return true;
          } else if (
              daysFromMultico >= 0 &&
              daysFromMultico < 60 &&
              this.firstLoad &&
              this.$route.name !== "migrate" &&
              !localStorage.getItem("from").includes("migrate")
          ) {
            this.$popin("alertMigrate", user, false);
            return true;
          } else if (
              daysFromMultico >= 0 &&
              !this.getCookie() &&
              this.firstLoad &&
              this.$route.name !== "migrate" &&
              !localStorage.getItem("from").includes("migrate")
          ) {
            let date = new Date(multicoDate);
            date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
            document.cookie =
                "firstmultico=true; expires=" + date.toGMTString();

            this.$popin("alertMigrate", user, false);
            return true;
          }
        }
      }
    },
    waitUserReadyBeforeRedirect(loop = 0) {
      if(this.user){
        this.redirect(this.user);
      }else{
        if(loop > 5) return;
        setTimeout(() => {
          loop++;
          this.waitUserReadyBeforeRedirect(loop);
        }, 200);
      }
    }
  },
  data() {
    return {
      reload: false,
      accessible: true,
      firstLoad: true,
      isMigrating : false
    };
  },
  computed: {
    legacy() {
      return this.$store.getters.legacy();
    },
    popins() {
      return this.$store.getters.popin();
    },
    requireUser() {
      return (
          ["login", "register", "webinar", "migrate", "auto-login"].indexOf(
              this.$route.name
          ) === -1
      );
    },
    error() {
      return this.$store.getters.error();
    },
    alert() {
      return this.$store.getters.alert();
    },
    user() {
      return this.$user();
    },
    backgroundTask() {
      return this.$store.getters.backgroundTask();
    },
    membership() {
      return this.$store.getters.membership();
    },
    podcast() {
      return this.$store.getters.podcast();
    },
    type() {
      return this.$store.getters.type();
    },
    renew() {
      return (
          this.membership &&
          (this.membership.snpi.total_amount ||
              this.membership.vhs.total_amount ||
              this.membership.asseris.total_amount ||
              this.membership.caci.total_amount)
      );
    },
    config() {
      return this.$config();
    },
    loaded() {
      return this.config && (!this.requireUser || this.user.roles) && (!this.user.isCommercialAgent || this.user.isRegistering || this.$store.getters.attachedAccounts());
    },
    preventAction() {
      return (
          ["login", "order", "webinar"].indexOf(this.$route.name) > -1 ||
          this.$route.params.page === "assurances"
      );
    }
  },
  watch: {
    config(oldConfig, newConfig) {
      if (oldConfig && newConfig && hash(oldConfig) !== hash(newConfig)) {
        this.$update();
      }
    },
    membership(data) {
      if (!this.preventAction) {
        if (this.renew && !this.isMigrating) this.$popin("renew", data, true, 99999);
        else if (
            this.hasRole(this.user, "company") &&
            data.rcp.enabled &&
            !this.user.company.sales
        )
          this.$popin("sales", this.user.company.id, false);
        else if (
            this.hasRole(this.user, "company") &&
            data.rcp.enabled &&
            !this.user.company.turnover
        )
          this.$popin("turnover", this.user.company.id, true);
      }
    },
    $route(to, from) {
      this.firstLoad = to.name === "home" && from.name === "login";
      this.waitUserReadyBeforeRedirect();
    },
    loaded(value) {
      if (!this.preventAction && value) {
        if (this.firstLoad && this.user && this.user.pendingOrders.length > 0)
          this.$popin("pending-orders", false, true, 0);
      }
    }
  },
  mounted() {
    if (this.$route.name === "wait") return;

    window.addEventListener("resize", this.resize);

    this.resize();

    this.$loadGoogleApi(["places"]);
    this.$update(cache.clear);

    configRepository.get().then((response) => {
      if (document.location.pathname === "/maintenance") {
        document.location.href = "/";
        return;
      }

      this.$heartbeat();

      if (this.$route.name === "auto-login" || this.$route.name === "webinar")
        return;

      userRepository.get().then(
          async (user) => {

            if (Vue.rollbar)
              Vue.rollbar.configure({ payload: { person: { id: user.id } } });

            window.BugHerdConfig.metadata = { user_id: user.id, type: user.type };

            if (!(user.type && !user.isRegistering)) return;

            termRepository.get();

            if (this.$route.name === "migrate")
              return;

            if(user.isCommercialAgent){
              await caciRepository.getAttachedAccounts(user.contact.id);
            }
            else{
              await companyRepository.getRelatedCompanies();
            }

            if(this.$route.name === "order")
              return;

            if (!this.redirect(user)) {
              userRepository.getMembership();
              appendixRepository.sync();
            }
          },
          (response) => {}
      );
    });
  },
};
</script>
