<template>
  <div class="v-login" :class="{'v-login--caci': type === 'caci'}">
    <div class="v-login__container">
      <div class="v-login__center">
        <div class="v-login__content">
          <a class="v-login__back icon-flex" v-if="step2" data-icon="chevron-left" @click="step2 = false">Retour</a>
          <a class="v-login__back icon-flex" v-else-if="type === 'caci'" data-icon="chevron-left" :href="snpi_url">Retour</a>

          <div v-if="type !== 'snpi' || step2">
            <div class="v-login__logo">
              <img :src="'/img/logo-'+type+'.svg'"/>
              <div class="v-login__baseline" v-if="type === 'snpi'">
                Premier syndicat<br/> français de l'immobilier<br/><small>depuis 1963</small>
              </div>
            </div>

            <h1 class="v-login__title">
              <span v-if="type === 'vhs'">Client VHS Business School</span>
              <span v-else-if="type === 'caci'">Espace adhérent des agents commerciaux</span>
              <span v-else>Espace adhérent SNPI</span>
            </h1>
            <form @submit.prevent="login" class="v-login__form">

              <div :class="{'disabled':loading||connected}">

                <vue-form-generator tag="div" :schema="formLogin" :model="params" :options="formOptions" class="v-login__form-main"></vue-form-generator>

                <div class="v-login__actions">
                  <vue-form-generator tag="div" :schema="rememberLogin" :model="params" :options="formOptions"></vue-form-generator>
                  <div @click="forgotten()" class="v-login__forgotten" v-if="!companies.length">Mot de passe oublié ?</div>
                </div>
              </div>

              <div class="bottom-right">
                <a v-if="type === 'snpi' && !loading && !connected && !companies.length" href="https://www.snpi.fr/formulaire-adhesion" class="button button--white">Adhérer</a>
                <button v-if="type !== 'snpi' && !loading && !connected && config.registration.enabled && !companies.length" class="button button--white" @click.prevent="userRegister" type="button">Inscription</button>
                <span class="v-login__sync" v-if="wait">Veuillez patienter...</span>
                <span class="v-login__sync" v-if="syncing">Synchronisation en cours...</span>
                <submit :loading="loading" text="Connexion" :success="connected" text-success="Connecté" :error="error" :text-error="error"></submit>
              </div>
            </form>
          </div>
          <div class="v-login__choice" v-else>
            <div>
              <div @click="step2 = true" class="v-login__box">
                 <div class="v-login__img">
                   <img src="/img/logo_snpi.svg" class="v-login__snpi" />
                </div>
              <div class="v-login__text">
                Adhérent SNPI
              </div>
              </div>
            </div>
              <br> <br>
            <div>
              <a :href="caci_url" class="v-login__box">
                  <div class="v-login__img">
                     <img src="/img/logo-caci.svg" class="v-login__caci" />
                  </div>
                  <div class="v-login__text">
                    MEMBRE DU C.A.C.I.
                 </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <footer class="v-login__footer">

        <div class="flex social-btns">
          <a class="app-btn" href="https://apps.apple.com/fr/app/snpi-news/id1603269091" target="_blank">
            <i data-icon="apple"></i>
            <p>Télécharger dans  <br/> <span class="big-txt">l' App Store</span></p>
          </a>

          <a class="app-btn" href="https://play.google.com/store/apps/details?id=com.snpi.news&hl=fr&gl=US" target="_blank">
            <i  data-icon="google-play"></i>
            <p>DISPONIBLE SUR <br/> <span class="big-txt">Google Play</span></p>
          </a>
        </div>

        <div class="v-login__footer-container">
          <div class="v-login__footer-infos">
            <a data-icon="telephone" href="tel:01 53 64 91 91">01 53 64 91 91</a>
            <a data-icon="mail" href="mailto:contact@snpi.fr">contact@snpi.fr</a>
          </div>
        </div>
      </footer>
    </div>

    <div class="v-login__visual">
      <div class="v-login__logos">
        <div>
          <a href="https://www.snpi.fr" target="_blank"><img  src="/img/logo_snpi.svg" /></a>
        </div>
        <div>
          <a href="https://www.snpi.fr" target="_blank"><img  src="/img/logo-caci.svg" /></a>
        </div>
        <div>
          <a href="https://www.snpicampus.fr/" target="_blank"><img src="/img/logo_snpi_campus.svg" /></a>
        </div>
        <div>
          <a href="https://www.snpiassurances.fr" target="_blank"><img src="/img/logo_snpi_assurances.png" /></a>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import userRepository from '@/repositories/userRepository';
import appendixRepository from '@/repositories/appendixRepository';
import termRepository from '@/repositories/termRepository';
import companyRepository from '../repositories/companyRepository';
import caciRepository from "@/repositories/caciRepository";

import Vue from 'vue';

export default {
  name: "Login",
  data(){
    return{
      loading: false,
      connected: false,
      syncing: false,
      wait: false,
      error: false,
      switch_company: false,
      type: 'snpi',
      caci_url: process.env.VUE_APP_CACI,
      snpi_url: process.env.VUE_APP_SNPI,
      step2: false,
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      },
      params:{
        login:'',
        password:'',
        company:'',
        remember: false
      },
      formLogin: {
      },
      rememberLogin: {
        fields: [
          {
            type: "checkbox-custom",
            inputName: "remember",
            model: "remember",
            label: "Se souvenir de moi",
          }
        ]
      },
    }
  },
  methods:{
    login(){

      this.step2 = true;

      let self = this;
      this.loading = true;

      let waitTimer = setTimeout(()=>{ self.wait = true }, 2000);

      userRepository.login(this.params).then(async user=>{

        clearTimeout(waitTimer);

        if(!user.isRegistering){
          if(user.isCommercialAgent){
            caciRepository.getAttachedAccounts(user.contact.id);
          }
          else{
            companyRepository.getRelatedCompanies();
          }
        }

        if(this.switch_company){
          localStorage.setItem('current_company', this.switch_company);
          window.location.href = "/"
        }
        else {


          let redirect = false;

          if (user.isCommercialAgent && window.location.protocol + '//' + window.location.host !== process.env.VUE_APP_CACI)
            redirect = process.env.VUE_APP_CACI;
          else if (user.isStudent && window.location.protocol + '//' + window.location.host !== process.env.VUE_APP_VHS)
            redirect = process.env.VUE_APP_VHS;
          else if (!user.isStudent && !user.isCommercialAgent && window.location.protocol + '//' + window.location.host !== process.env.VUE_APP_SNPI)
            redirect = process.env.VUE_APP_SNPI;

          if (redirect) {

            localStorage.clear();
            document.location.href = redirect;
            return;
          }

          if (Vue.rollbar)
            Vue.rollbar.configure({payload: {person: {id: user.id}}});

          window.BugHerdConfig.metadata = {user_id: user.id, type: user.type};

          let syncTimer = setTimeout(() => {
            self.wait = false;
            self.syncing = true
          }, 2000);

          appendixRepository.regenerateCertificates(user).then(response => {

                if (user.type && !user.isRegistering) {

                  termRepository.get();
                  userRepository.getMembership();
                  appendixRepository.sync();
                }

                clearTimeout(syncTimer);

                this.loading = false;
                this.connected = true;
                this.syncing = false;
                this.wait = false;

                setTimeout(function () {

                  let from = localStorage.getItem('from');

                  if (user.isRegistering)
                    self.$router.push({name: 'register'});
                  else if (from && from !== '/register' && from !== '/user/login')
                    self.$router.push({path: from});
                  else
                    self.$router.push({name: 'home'});

                }, 500);
              },
              response => {

                clearTimeout(syncTimer);
                this.wait = false;
                this.loading = false;
              });
        }

      }, response=>{

        clearTimeout(waitTimer);

        if( this.companies.length && !this.params.company )
          this.params.company = this.companies[0].id;

        this.wait = false;
        this.loading = false;
      })
    },
    forgotten(){
      this.$popin('forgotten-password');
    },
    userRegister(){
      this.$popin('user-register');
    },
    qualiopiSondage(){
      this.$popin('questionnaire-froid');
    },
    qualiopiPositionning(){
      this.$popin('questionnaire-positionnement');
    },
  },
  computed:{
    config(){ return this.$config() },
    companies(){ return this.$store.getters.companies() }
  },
  mounted() {

    let self = this;

    this.type = this.$store.getters.type();

    this.formLogin={
      fields: [
        {
          type: "input",
          inputType: "text",
          model: "login",
          required: true,
          autocomplete:'username',
          attributes: {'autofocus':true},
          label: this.type === 'vhs' ? "Email" : this.type === 'caci' ? "Numéro d'adhérent ou email" : "Adresse mail",
          placeholder: this.type === 'vhs' ? "jean@dupond.fr" : this.type === 'caci' ? "jean@gmail.com" : "12345"
        },
        {
          type: "password-custom",
          model: "password",
          required: true,
          autocomplete:'current-password',
          label: "Mot de passe",
        },
        {
          type: "select",
          model: "company",
          styleClasses: "fields-label",
          required: true,
          label: "Société",
          selectOptions:{
            noneSelectedText: "---"
          },
          values(){
            return self.companies
          },
          visible(){ return self.companies.length }
        }
      ]
    }

    if(this.type === 'vhs' && localStorage.getItem('from') === '/register')
      this.userRegister()
    else if(localStorage.getItem('recover')) {
      this.forgotten()
      localStorage.removeItem('recover')
    } else if( localStorage.getItem('email') ){
      this.params.login = localStorage.getItem('email')
      this.step2 = true
      localStorage.removeItem('email')
    }
    else if(localStorage.getItem('from') === '/formations/sondage' && localStorage.getItem('key') !== null){
      this.qualiopiSondage()
    }
    else if(localStorage.getItem('from') === '/formations/positionnement' && localStorage.getItem('key') !== null){
      this.qualiopiPositionning()
    }

    if( localStorage.getItem('login') ){

      this.params.login = localStorage.getItem('login');
      this.params.remember = true;
    }

    if( this.$route.query.token ){
      this.params.token = this.$route.query.token;
      this.login();
    }

    if( this.$route.params.token ){
      this.params.token = this.$route.params.token;
      this.login();
    }

    if( this.$route.query.auto_token ){
      let token = atob(this.$route.query.auto_token);
      this.params.token = token;
      this.switch_company = this.$route.query.header;
      this.login();
    }
  }
}
</script>

<style lang="scss">
@use "sass:math";
@import '../environment';

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-login{
  $c: &;

  display: flex; justify-content: center; height: 100vh; color: $c-primary; background: var(--c-background);

  .ios &{
    height: var(--app-height);
  }

  &__uptitle{
    font-size: $font-xxl;
    & +*{ margin-top: $space-l }
  }

  &--caci{
    .v-login__type{ color: $c-primary }
  }
  & > *{
    width: 50%;
    @media #{$to-tablet}{ width: 90% }
  }

  &__center{ display: flex; justify-content: center; align-items: center; flex-direction: column; flex: 1 }
  &__sync{ display: inline-block; margin-right: 1rem }

  &__container{ display: flex; flex-direction: column }

  &__content{
    text-align: left; max-width: 37rem; width: 100%; position:relative; margin: $space-m 0;
  }

  &__forgotten{ font-size: $font-s }

  &__title{
    font-weight: bold; font-size: 2rem; margin-top: $space;
    .app-vhs &{ font-weight: normal }
  }

  &__visual{
    background: url('/img/login.svg') no-repeat center;
    background-size: cover; position: relative;

    @media #{$to-tablet}{ display: none }
  }

  &__logo{
    display: flex; font-family:$font-main; text-transform: uppercase; color: $c-main; font-size: 1.5rem;
    align-items: center;
    small{ font-size: 70%; font-style: italic }
    img{
      height: 6rem; width: auto; flex-shrink: 0; margin-right: 1rem;
      .app-caci &{ height: 11rem }
    }
  }

  &__type{ position: absolute; top: 2rem; color: $c-secondary; text-decoration: underline }
  &__register{
    text-align: right; margin-top: $space-s;
    a{ text-decoration: underline }
  }
  &__form{
    margin-top: $space-m;
    .field-input label, .field-password-custom label{
      font-weight: bold; font-size: $font-xs; text-transform: uppercase;
      .app-vhs &{ font-weight: normal; color: $c-text; text-transform: none }
    }
    .field-checkbox-custom input[type=checkbox]{
      .app-vhs &{ border-color: $c-text }
    }
    .custom-password__show{ background: none }
    .field-wrap{ margin-top: 0.5rem }
    .form-group + .form-group{ margin-top: $space-m }
  }

  &__form-main input{
    background: #fff;
    &::placeholder{ color: $c-main }
  }

  &__actions{
    display: flex; justify-content: space-between; margin-top: $space-m; align-items: center;

    & > *{ cursor: pointer }
    label{ text-transform: none; font-weight: normal }
  }

  &__asseris{ width: 60px; position: absolute; bottom: 2rem; }

  &__img{
    background: #fff;
    width: 31rem;
    padding: 28px;
    height: 11rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 10px
  }

  &__text{
     margin-top: 2rem;
     font-size: 18px;
   }


  &__choice{
    display: flex; flex-direction: column;  color: $c-primary-light;

    & > *{
      flex: 1; padding-top: calc(45% - #{math.div($space-s,2)} ); border-radius: $border-radius; position: relative; transition: allow(box-shadow);
      cursor: pointer; //box-shadow: $subtle-box-shadow;

      /*.no-touch &:hover{ box-shadow: $selected-box-shadow } */

      & + *{ margin-left: $space-s }

      & > *{ padding: $space $space-m; display: block; position: overlay(); }
    }
  }
  &__box{ font-weight: bold; display:flex; flex-direction: column; justify-content: space-between; align-items: center; text-transform: uppercase; font-size: $font-xs }

  &__footer{
    width: 100%; padding: $space-m;
    @media #{$to-phone}{ margin-bottom: $space-m; padding: 0; margin-top: 4vh }
    &-container{ max-width: 36rem; margin: auto }
    &-infos{
      background: $c-primary; color: white; border-radius: $border-radius; font-weight: bold; margin-top: $space-s; padding: math.div($space-s,2);
      & > *{
        &:before{ font-size: $font-xxl; margin-right: math.div($space-s,2) }
        & + *{ margin-left: $space-l }
      }
    }
  }

  &__snpi{     margin-top: -8px;
    max-width: 23rem;
    position: relative;
    top: 4px;
    
    }
  &__caci{ margin-top: -8px; max-width: 23rem;
    position: relative;
    top: 4px;
    right: 11px; }

  &__back{
    position: absolute; top: -4rem; font-size: $font-xxs; text-transform: uppercase; letter-spacing: 0.05em; cursor: pointer;
    &:before{ margin-top: 0 }
  }

  &__logos{
    position: absolute; bottom: 2rem; left: 2rem; right: 2rem; padding: 2rem; background: rgba(255,255,255,0.2);
    border-radius: $border-radius; display: flex; opacity: 0.8; justify-content: space-evenly; backdrop-filter: blur(10px);
    & > *{
      &	+ *{ margin-left: $space-m }
      img{ max-height:3em; width: 100% }
    }
  }

  // Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 

 }

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) { 

  .v-login{

    &__center{ display: flex; justify-content: center; align-items: center; flex-direction: column; justify-content: space-between; flex: none; margin-top: 6vh; }
  
  }

 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 

 }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 

 }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 

 }
}

</style>
